/************ Navbar Css ************/

nav {
  width: 100%;
  height: 100px;
  display: none !important;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  transition: all 300ms ease-in;
  border-bottom: 1px solid rgba(131, 131, 131, 0.099);
}

nav.scrolled {
  display: flex !important;
  background-color: #1b1b1b;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a.logo img {
  width: 80px;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
}

.nav-links li {
  padding: 0 30px;
  font-size: 20px;
  font-weight: 400;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
}

.nav-links a:hover {
  transition: .3s ease;
}

.nav-links a:hover {
  color: #00b1a5;
}

.active-nav {
  color: #00b1a5 !important;
}

.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon svg{
  background-color: #00bfb2;
  width: 25px;
  height: 25px;
  border: 1px solid #00bfb2;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  color: #121212;
  transition: 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
}

.social-icon svg:hover{
  border: 1px solid #121212;
  box-shadow: inset 0px 0px 0px 50px #fff;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
}

.navbar-text button:hover {
  box-shadow: inset 150px 0 0 0 #fff;
  color: #121212;
}



/************ Hamb Menu ************/

.nav-toggle-btn {
  display: none;
}

/************ Media Queries ************/

@media screen and (max-width: 992px) {

  .nav-toggle-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    background: transparent;
    display: inline-block;
    font-size: 30px;
    width: 50px;
    height: 50px;
    border: 0;
  }

  .nav-toggle-btn {
    color: #00bfb2;
  }

  .nav-links {
    position: absolute;
    top: 100px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #1b1b1b;
    border-top: 1px solid rgba(97, 97, 97, 0.729);
  }

  .nav-links li {
    padding: 20px 0;
    margin-left: 50px;
  }

  .nav-links a {
    font-size: 25px;
  }

  .navbar-text .social-icon {
    position: fixed;
    bottom: 50%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 50px;
  }

  .social-icon svg {
    width: 20px !important;
    height: 20px !important;
  }

  .navbar-text button {
    display: none;
  }

  .show-nav {
    display: block;
  }

  .hide-nav {
    display: none;
  }

}
