/************ Footer ************/

.footer {
  padding: 30px 0;
  background-color: #262626;
  border-top: 1px solid rgba(102, 102, 102, 0.301);
  position: relative;
}

footer p {
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: inline-block;
}

.social-icon svg{
  background-color: #00bfb2;
  width: 25px;
  height: 25px;
  border: 1px solid #00bfb2;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  color: #121212;
  transition: 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
}

.social-icon svg:hover{
  border: 1px solid #121212;
  box-shadow: inset 0px 0px 0px 50px #fff;
}
