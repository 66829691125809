#home {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tsparticles {
  width: 100%;
  height: 100vh;
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  bottom: 50%;
}

.header-bx {
  position: relative;
}

.header-bx h1 {
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: #00a89d;
}

.header-bx p {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 30px;
  width: 96%;
}

.header-bx a {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 40px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header-bx a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.header-bx a:hover{
  color: #fff;
}

.header-bx a:hover svg {
  margin-left: 25px;
  color: #fff;
}

.header-btn {
  position: relative;
}

.header-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 15px;
  margin-right: 50px;
  letter-spacing: 0.8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header-btn a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.header-btn a:hover{
  color: #fff;
}

.header-btn a:hover svg {
  margin-left: 25px;
  color: #fff;
}

.scroll-down {
  border: 2px solid rgba(255, 255, 255, 0.653);
  border-radius: 20px;
  bottom: 40px;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 30px;
}

.scroll-down::before {
  content: '';
  height: 6px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 6px;
  background-color: rgba(255, 255, 255, 0.653);
  border-radius: 100%;
  animation: scrollDown 2s infinite;
}

@keyframes scrollDown {
  0%{
    opacity: 0;
    transform: translate(0,0);
  }

  40%{
    opacity: 1;
  }

  80%{
    opacity: 1;
    transform: translate(0,20px);
  }

  100%{
    opacity: 0;
  }
}

@media screen and (max-width: 576px) {
  .header-bx h1 {
    font-size: 60px;
  }
}


