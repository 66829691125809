.projectlist {
  width: 200px;
  height: 50px;
  font-weight: 300;
  color: #fff;
  border: 1px solid #fff;
  font-size: 18px;
	position: relative;
  margin: 30px 10px;
  padding: 10px;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #fff;
  border-radius: 2px;
  cursor: pointer;
}

.projectlist.active {
  box-shadow: inset 200px 0 0 0 #007e76;
  color: #fff;
  border: 1px solid #007e76;
}
