/************ Projects ************/
.projects {
  padding: 50px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: #00BFB2;
}

.project-pill {
  margin: 50px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-pill li:hover {
  border: 1px solid #00BFB2;
}

.project-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.project-item {
  margin: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
}

.project-item a{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  border-radius: 10px;
  width: 400px;
  height: 250px;
  border: 2px solid rgba(255, 255, 255, 0.037)
}

.project-item a:hover {
  background-color: rgba(0, 53, 153, 0.6);
  transition: all 0.4s linear;
  cursor: pointer;
  border: none;
}

.project-item:hover img {
  opacity: 0.2;
}

.project-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 10px;
}

.project-item h3 {
  position: absolute;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 4px;
}

.project-item h4 {
  position: absolute;
  font-size: 20px;
  color: #ffffff;
  bottom: 70px;
  font-weight: 400;
}

@media screen and (max-width: 576px) {

  .project-pill {
    display: inline-block;
    margin: 30px 0;
  }

  .project-item {
    width: 100%;
    height: 100%;
  }
}



